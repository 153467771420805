import { ServerError, ServerViolation } from '../store/storeTypes';
import React from 'react';

export interface IServerFormError {
  title: string;
  requestError: ServerError;
  isError: boolean;
}

const ServerFormError = ({ title, requestError, isError }: IServerFormError) => {
  const isConstraintViolation = requestError?.status === 400;

  if (!isError || !requestError) return null;

  return isConstraintViolation ? (
    <div className="text-error text-sm mt-2">
      <p>{title}</p>
      {requestError?.data?.violations?.map(
        (violation: ServerViolation, index: number) => (
          <p key={`violation-${index}`}>{violation.message || 'Unknown error'}</p>
        )
      )}
    </div>
  ) : (
    <div className="text-error text-sm mt-2">
      <p>{title}</p>
      <p>
        {typeof requestError.data === 'string' 
          ? requestError.data 
          : JSON.stringify(requestError.data) || 'An error occurred'}
      </p>
    </div>
  );
};

export default ServerFormError;