import React, { ReactElement } from 'react';

interface IChipWIthIconProps {
  icon: ReactElement;
  text: string;
  className: string;
  style?: React.CSSProperties;
}

const ChipWithIcon = ({ icon, text, className, style }: IChipWIthIconProps) => {
  return (
    <span className={"flex items-center justify-center gap-1 rounded-l text-center text-gray-700 border-gray-400  border-solid   " + className} style={style}>
        {icon} {text}
      </span>
  );
};

export default ChipWithIcon;
