import React, { Suspense, useEffect, useState, lazy } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetBatchByIdQuery } from 'src/store/services/batchApi';
import { useParams } from 'react-router';
import { useGetAssetQuery } from 'src/store/services/assetsApi';
import Loading from 'src/components/Loading';
import DownloadDocumentButton from 'src/components/DownloadDocumentButton';
import AssetsTable from 'src/components/AssetsTable';
import EventsTable from '../Events/components/EventsTable';
import CustomAccordion from '../../components/CustomAccordion';
import '../../../src/MarkdownStyles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import TagQRCode from '../Tag/components/TagContent/components/TagQRCode';
import EditBatchModal from '../../components/Modals/Batch/EditBatchModal';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import ChipWithIcon from '../../components/ChipWithIcon';
import { ReactComponent as IconChecklist } from 'src/assets/footer-menu/IconChecklist.svg';
import { ReactComponent as IconEdit } from 'src/assets/EditItem.svg';
import { ReactComponent as IconRegistration } from 'src/assets/home/IconRegistration.svg';
import { ReactComponent as IconTransport } from 'src/assets/IconTransport.svg';
import { ReactComponent as IconService } from 'src/assets/IconService.svg';
import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';
import { ReactComponent as IconLayers } from 'src/assets/footer-menu/IconLayers.svg';
import { ReactComponent as IconPlanned } from 'src/assets/IconPlanned.svg';
import { ReactComponent as IconStart } from 'src/assets/IconStart.svg';
import { ReactComponent as IconDone } from 'src/assets/IconDone.svg';
import { ReactComponent as IconCounter } from 'src/assets/IconCounter.svg';
import { batchState, batchType } from '../../store/services/batchApi/batchTypes';
import { useEditBatchMutation } from '../../store/services/batchApi';
import { getXAuthOwnerHeader } from '../../helpers/getXAuthOwnerHeader';

const ReactMarkdown = lazy(() => import('react-markdown'));

const BatchSingle = () => {
  const { t } = useTranslation('batches');
  const [currentPage, setCurrentPage] = useState(1);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(window.innerWidth >= 768);
  const [isBatchAccordionOpen, setIsBatchAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isMultiEditModalOpened, setIsMultiEditModalOpened] = useState<boolean>(false);
  const [editBatch] = useEditBatchMutation();

  const dispatch = useDispatch();
  const params = useParams<{ batch: string }>();
  const { data: batchData, isLoading: isBatchLoading, isSuccess: isBatchSuccess } = useGetBatchByIdQuery(params.batch);
  const location = useLocation();
  const batchUrl = `${process.env.REACT_APP_BATCH_URL_ROOT}/${batchData?.guid}`;

  // Reload if guid as batch id 
  useEffect(() => {
    if (isBatchSuccess && batchData?.id) {
      if (params.batch.includes('-')) {
        const { pathname } = location;
        window.location.href = pathname.split('/').slice(0,-1).join('/')+'/'+batchData?.id;  
      }
  }

  }, [batchData, isBatchSuccess, location, params.batch]);


  const pageSize = 10;
  const { data: assetsData, isLoading: isAssetsLoading } = useGetAssetQuery({
    pageSize,
    pageOffset: currentPage,
    batchId: params.batch,
  });

  const [gfm, setGfm] = useState<any>(null); 

  useEffect(() => {
    const loadGfm = async () => {
      const gfmModule = await import('remark-gfm');
      setGfm(() => gfmModule.default); 
    };

    loadGfm();
  }, []);

  if (isBatchLoading || isAssetsLoading || !gfm) {
    return <Loading />;
  }

  const getStyle = () => {
    const style = batchData?.state === 'IN_PROGRESS' ? { border: '3px solid #5F9548' } :
    batchData?.state === 'COMPLETED' ? { border: '3px solid #4C443C' } :
    batchData?.state === 'PLANNED' ? { border: '3px dashed #5F9548' } : {};
    return style;
  }

  const getTypeIcon = (type: batchType, className: string) =>
    type === 'ASSET_CREATION' ? <IconAdd className={className} /> :
    type === 'REGISTRATION' ? <IconRegistration className={className} /> :
    type === 'TRANSPORT' ? <IconTransport className={className} /> :
    type === 'SERVICE' ? <IconService className={className} /> :
    type === 'COUNT' ? <IconCounter className={className} /> :
    <IconLayers className={className} />;

  const getStateIcon = (state: batchState, className: string) =>
    state === 'PLANNED' ? <IconPlanned className={className} /> :
    state === 'IN_PROGRESS' ? <IconStart className={className} /> :
    state === 'COMPLETED' ? <IconDone className={className} /> :
    <></>;

  const getActionIcon = (state: batchState, className: string) =>
    state === 'PLANNED' ? <IconStart className={className} /> :
    state === 'IN_PROGRESS' ? <IconDone className={className} /> :
    state === 'COMPLETED' ? <IconPlanned className={className} /> :
    <></>;

  const getActionText = (state: batchState) =>
    state === 'PLANNED' ? t('context-label-start') :
    state === 'IN_PROGRESS' ? t('context-label-close') :
    state === 'COMPLETED' ? t('context-label-restart') :
    "";

  const nextState = (state: batchState) =>
    state === 'PLANNED' ? 'IN_PROGRESS':
    state === 'IN_PROGRESS' ? 'COMPLETED' :
    'PLANNED';

  const batchAction = (state: batchState) =>
      editBatch({
        batchId: batchData?.id,
        body: { state },
        ...getXAuthOwnerHeader(batchData?.owner.name || ''),
      });

  // batchData?.type === 'ASSET_CREATION'



  return (
    <div className="p-4 space-y-4">
  
      <div className="font-mukta-mahee text-grey-asset">
        <h1 className="font-bold text-[32px] my-[22px]">{batchData?.name}</h1>

        <div className="markdown-editor">
          <Suspense fallback={<div>Loading markdown...</div>}>
            <ReactMarkdown remarkPlugins={gfm ? [gfm] : []}>
              {batchData?.description}
            </ReactMarkdown>
          </Suspense>
        </div>


        <p className="flex justify-start items-center flex-wrap my-10 py-10">
            {batchData?.guid && (
                    <TagQRCode
                      tag={batchData.guid.split('-')[0]}
                      url={batchUrl}
                    />
                  )}
        </p>

        <div className="flex my-10 py-10" >
        <ChipWithIcon icon={getTypeIcon(batchData!.type!, "w-5 h-5 fill-gray-500")} text={t(`batch-type-${batchData?.type?.toLowerCase()}-option`).toUpperCase()}  className=' border-dashed  border-2  py-2 px-5 m-2 ' />

        <ChipWithIcon icon={getStateIcon(batchData!.state, "w-5 h-5 fill-gray-500")} text={t(`batch-state-${batchData?.state.toLowerCase()}-option`).toUpperCase()}  className=' border-dashed  border-2  py-2 px-5 m-2 ' style={getStyle()} />

       {(batchData?.state === 'PLANNED' || batchData?.state === 'IN_PROGRESS') && (
        <ButtonWithIcon
          className="flex items-center justify-center gap-1 min-w-[120px] px-10 py-1 bg-tag text-white border-none rounded text-lg leading-normal text-center !outline-transparent transition-colors duration-300 ease-linear m-2 "
               icon={getActionIcon(batchData!.state, "w-6 h-6  fill-white")}
                text={getActionText(batchData!.state)}
                onClick={() => batchAction(nextState(batchData!.state))}
                />

       )}
        </div>

        {assetsData?.assets && (assetsData!.assets.length > 0) && (
          <CustomAccordion
            isAccordionOpen={isAccordionOpen}
            setIsAccordionOpen={setIsAccordionOpen}
            title="Assets"
          >
            <AssetsTable
              data={assetsData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <DownloadDocumentButton type="batch" id={params.batch} />
            <ButtonWithIcon
                className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300  bg-white ease-linear m-2"
                icon={<IconChecklist className="w-6 h-6 fill-tag" />}
                text={t('edit-batch-assets')}
                onClick={() => setIsMultiEditModalOpened(true)}
                />
          </CustomAccordion>
        )}
      </div>

      <CustomAccordion
        title={t('latest-events-title')}
        isAccordionOpen={isBatchAccordionOpen}
        setIsAccordionOpen={setIsBatchAccordionOpen}
      >
        <EventsTable defaultId={params.batch} defaultType={'BATCH'} pageSize={5} />
      </CustomAccordion>

      <ButtonWithIcon
          className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
          icon={<IconEdit className="w-6 h-6 fill-tag" />}
          text={t('edit-batch')}
          onClick={() => setIsModalOpened(true)}
          />


      <EditBatchModal
          onClose={() => setIsModalOpened(false)}
          isOpened={isModalOpened}
          data={batchData}
          editMode="editBatch"
      />

      <EditBatchModal
          onClose={() => setIsMultiEditModalOpened(false)}
          isOpened={isMultiEditModalOpened}
          data={batchData}
          editMode="editBatchAssets"
      />

    </div>
  );
};

export default BatchSingle;
