import AssetTagBadge from '../../../../../components/AssetTagBadge';
import QRCode from 'react-qr-code';

interface ITagQRCode {
  tag: string;
  url: string;
}

const TagQRCode = ({ tag, url }: ITagQRCode) => {
  return (
    <div className="flex flex-col items-center gap-2 p-3">
      <QRCode value={url} className="w-16 h-16" />
      <div className="max-h-[30px]">
        <AssetTagBadge tag={tag} />
      </div>
    </div>
  );
};

export default TagQRCode;