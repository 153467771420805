import { useCallback, useEffect, useState } from 'react';
import ScanButton from '../../../../../../views/Home/components/ScanButton';
import CustomSelect from 'src/components/CustomSelect';
import { useTranslation } from 'react-i18next';

interface IBatchQRScanner {
  setFieldValue: (field: string, value: any) => void;
  guid?: string;
  placeholder: string;
}

const BatchQRScanner = ({ setFieldValue, guid, placeholder }: IBatchQRScanner) => {
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const hasValue = Boolean(guid);

  const handleScannerToggle = useCallback(() => {
    setIsScannerOpen((prev) => !prev);
  }, []);

  return (
    <div className="relative flex items-center gap-3 w-full">
      <ScanButton
        isOpen={isScannerOpen}
        handleScannerOpen={handleScannerToggle}
        handleScannerClose={handleScannerToggle}
        scannerMode="batchQR"
        setFieldValue={setFieldValue}
      />
      <div className="relative w-full">
      <CustomSelect
        isClearable
        isDisabled={false}
        options={[]}
        value={guid ? {
            label: guid.split('-')[0], 
            value: guid 
          } : null}
        onSelect={() => setFieldValue('guid', '')}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder=""
        hideDropdownIndicator
        menuIsOpen={false}
        />
        <label
          className={`absolute left-[10px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
            hasValue || isFocused ? '!-top-1 text-xs' : 'top-[50%] -translate-y-1/2 text-base'
          }`}
          style={{ width: 'calc(100% - 40px)' }}
        >
          {placeholder}
        </label>
      </div>
      {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );
};

export default BatchQRScanner;
