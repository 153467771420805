import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useGetLocationByIdQuery } from 'src/store/services/locationApi';
import { useParams } from 'react-router';
import { useGetAssetQuery } from 'src/store/services/assetsApi';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import DownloadDocumentButton from 'src/components/DownloadDocumentButton';
import EventsTable from '../Events/components/EventsTable';
import AssetsTable from '../../components/AssetsTable';
import CustomAccordion from '../../components/CustomAccordion';
import EditLocationModal from '../../components/Modals/Location/EditLocation';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { ReactComponent as IconEdit } from 'src/assets/EditItem.svg';
import '../../../src/MarkdownStyles.css';

const ReactMarkdown = lazy(() => import('react-markdown'));

const LocationSingle = () => {
  const { t } = useTranslation();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(window.innerWidth >= 768);
  const [isLocationAccordionOpen, setIsLocationAccordionOpen] = useState<boolean>(
    window.innerWidth >= 768,
  );
  const [gfm, setGfm] = useState<any>(null); 

  const pageSize = 10;
  const params = useParams<{ locationId: string }>();
  const { data: locationData, isLoading: isLocationLoading } = useGetLocationByIdQuery(
    params.locationId,
  );
  const { data: locationAssetsData, isLoading: isLocationAssetsLoading } = useGetAssetQuery({
    pageSize,
    pageOffset: currentPage,
    locationId: params.locationId,
  });

  useEffect(() => {
    const loadGfm = async () => {
      const gfmModule = await import('remark-gfm');
      setGfm(() => gfmModule.default); 
    };

    loadGfm();
  }, []);

  if (isLocationLoading || isLocationAssetsLoading || !gfm) {
    return <Loading />;
  }

  return (
    <div className="p-4 space-y-4">
      <div className="font-mukta-mahee text-grey-asset ">
        <h1 className="font-bold text-[32px] my-[22px]">{locationData?.name}</h1>
        <div className="markdown-editor">
          <Suspense fallback={<div>Loading markdown...</div>}>
            <ReactMarkdown remarkPlugins={[gfm]}>
              {locationData?.description}
            </ReactMarkdown>
          </Suspense>
        </div>
        <ul>
          <li>{locationData?.address.streetAddress}</li>
          <li>
            {locationData?.address.postalCode} {locationData?.address.city}
          </li>
          <li>{locationData?.address.country}</li>
        </ul>
        {!!locationAssetsData?.assets.length && (
          <CustomAccordion
            isAccordionOpen={isAccordionOpen}
            setIsAccordionOpen={setIsAccordionOpen}
            title="Assets"
          >
            <AssetsTable
              data={locationAssetsData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            <DownloadDocumentButton type="location" id={params.locationId} />
          </CustomAccordion>
        )}
      </div>
      <CustomAccordion
        title={t('latest-events-title', { ns: 'batches' })}
        isAccordionOpen={isLocationAccordionOpen}
        setIsAccordionOpen={setIsLocationAccordionOpen}
      >
        <EventsTable defaultId={params.locationId} defaultType={'LOCATION'} pageSize={5} />
      </CustomAccordion>
      <ButtonWithIcon
          className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
          icon={<IconEdit className="w-6 h-6 fill-tag" />}
          text={t('edit-location', { ns: 'location' })}
          onClick={() => setIsModalOpened(true)}
          />

      <EditLocationModal
          onClose={() => setIsModalOpened(false)}
          isOpened={isModalOpened}
        data={locationData}
      />
    </div>
  );
};

export default React.memo(LocationSingle);
