import React from 'react';
import { useTranslation } from 'react-i18next';
import { ILocation } from '../../../../../../store/services/locationApi/locationTypes';
import { IBatchData } from '../../../../../../store/services/batchApi/batchTypes';
import BatchesBadge from '../BatchesBadge';
import LocalesBadge from '../LocationBadge';
import { ITagStates } from '../TagDetails';

interface IAuthenticatedTagInfoProps {
  location?: ILocation;
  batches?: IBatchData[];
  isAuthenticated: boolean;
  state: ITagStates
}

const AuthenticatedTagInfo = ({
  location,
  batches,
  isAuthenticated,
  state
}: IAuthenticatedTagInfoProps) => {
  const { t } = useTranslation('tag');
  return isAuthenticated ? (

    <>
      <BatchesBadge batches={batches || []} />
      <LocalesBadge local={location || null} />



      <div className="flex flex-wrap gap-2">
        <h5 className="block py-1 px-2 my-2 mx-0.5
            bg-state-badge
            transition-colors duration-300 ease-in-out
            text-xs text-state-badge-foreground-color text-center
            rounded-xl
            cursor-pointer">
        {t(`table-property-state`)}: {t(`asset-state-${state.toLowerCase()}-option`)}
        </h5>
      </div>
    </>
  ) : null;
};

export default React.memo(AuthenticatedTagInfo);
